.table, .table-bordered{
    font-size: 12px;    
    line-height: 12px;
    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }
    td{
        border-color: #c4cdd5;
    }
    th,td{
        padding: 10px;
        color: #0062f4;
    }
    thead{
        th{
            color: #364760;
            font-family: "Poppins-Medium";
            border-bottom: 0px;
            text-transform: uppercase;
            border-top:0px;
            &:focus{
                outline: 0px;
            }
            &.sortable{
                cursor: pointer;
                .order{
                    .dropdown{
                        .caret{
                            margin: 0px 0 0px 5px;
                            color: #cccccc;
                        }
                    }
                    .dropup {
                        .caret{
                            margin: 0px;
                            color: #cccccc;
                            content: "";
                            border-top: 0;
                            border-bottom: 4px dashed;
                        }
                    }                    
                }
            }           
        }
    }
    &.table-hover{
        tbody{
            tr{
                &:hover{
                    background-color: #f4f6f8;
                    cursor: pointer;
                    transition: all .5s ease;
                }
            }
        }
    }
    &.table-active{
        tbody{
            tr{
                &:hover{
                    background-color: #f4f6f8;
                    cursor: pointer;
                    transition: all .5s ease;
                }
            }
        }
    }
    &.table-active-row{
        tbody{
            tr{
                &:hover{
                    background-color: #0070e0;
                    cursor: pointer;
                    transition: all .5s ease;
                }
            }
        }
    }
}
.comment-box {
    padding: 0px 12px 0px 12px;
    display: flex;

   // padding: 0px 12px 13px 12px;
    //display: flex;

    .form-group {
        width: 100%;
        margin-right: 0px;
    }

    .commentlabel {
        background-color: transparent;
       
        padding: 0px 20px;
        font-family: "OpenSans-Semibold";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
    }
    .comment-area {
      //  border: 1px solid rgb(192, 192, 192);
        z-index: 1;
        background: #ffffff;
       // border: "solid 1px #c0c0c0" ;
       // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
        padding: 0px 0px 0px !important;
      //  border-top: 1px solid #e0e3e6;
       // position: fixed;
        //bottom: 72px;
      //  bottom: 0;
      margin-top: -7px;
        width: 100%;
        .add-comment {
            margin-top: 12px;
        }
        .profile {
            img {
                margin-right: 12px;
                border-radius: 50%;
                background-color: #fff;
                width: 36px;
                height: 36px;
                border: 1px solid transparent;
                &.default {
                    border-color: #ccc;
                }
            }
        }
        .inpute-field {
            width: 100%;
            //calc(100% - 48px);
           
        }
        .mentions__mention {
            .mentions__mention__control {
                min-height: 124px;
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                background-color: #fff;
                transition: all 0.25s ease-in-out;
                .mentions__mention__highlighter {
                    padding: 9px;
                    border: 1px solid transparent;
                    border-radius: 0.25rem;
                    .mentions__mention {
                        color: #2caf92;
                        z-index: 1;
                        position: relative;
                    }
                }
                .mentions__mention__input {
                    padding: 9px;
                    border: 1px solid #ced4da;
                    border-radius: 0.5rem;
                    color: #1d1e20;
                    transition: all 0.25s ease-in-out;
                    &::placeholder {
                        color: #84888c;
                    }
                    &:focus {
                        border-color: #2caf92;
                    }
                    &:focus-visible {
                        outline: 0px;
                    }
                }
            }
            .mentions__mention__suggestions {
                background: #ffffff;
                border: 1px solid #e0e3e6;
                box-sizing: border-box;
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                top: unset !important;
                bottom: 40px;
                //top: 0px !important;
                //left: 10px !important;
                .mentions__mention__suggestions__list {
                    width: 300px;
                    max-height: 300px;
                    overflow: auto;
                }
                .mentions__mention__suggestions__item {
                    padding: 10px 16px;
                    font-family: "OpenSans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 2px;
                    .profile {
                        //margin-right: 16px;
                        img {
                            border-radius: 50%;
                            background-color: #fff;
                            width: 36px;
                            height: 36px;
                            border: 1px solid transparent;
                            &.default {
                                border-color: #ccc;
                            }
                        }
                    }
                    .user-name {
                        color: #1d1e20;
                    }
                    .user-pos {
                        color: #6a6e73;
                        font-weight: normal;
                    }
                    &.mentions__mention__suggestions__item--focused {
                        background: #e5f5ff;
                    }
                }
            }
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    }

    .comment-list {
        padding: 24px 32px;
       // border-top: 1px solid #e0e3e6;
        color: #1d1e20;
        font-family: "OpenSans";
        margin-bottom: 0px;

        .no-comment-placeholder {
            color: #84888c;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
        }
        .sec-ttl {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 16px;
            color: #1d1e20;
        }
        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
            li {
                margin-bottom: 15px;
                img {
                    margin-right: 12px;
                    border-radius: 50%;
                    background-color: #fff;
                    width: 36px;
                    height: 36px;
                    border: 1px solid transparent;
                    &.default {
                        border-color: #ccc;
                    }
                }
                .profile-details {
                    font-style: normal;
                    font-size: 14px;
                    line-height: 18px;
                    .name {
                        font-weight: 600;
                        margin-bottom: 4px;
                        span {
                            color: #6a6e73;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 16px;
                            margin-left: 5px;
                        }
                    }
                    .comment {
                        font-weight: normal;
                        span {
                            color: #2caf92;
                            &.isNotPermission {
                                color: #1d1e20;
                            }
                        }
                    }
                }
            }
        }
    }
    &.add-comment-button{
        .comment-list{
            margin-bottom: 52px;
        }
    }
}

.disabled-input{    
    .comment-box{
        .comment-area{            
            .inpute-field{
                opacity: 0.7;
                pointer-events: none;
            }
        }
    }
}

.footer-show {
    .comment-box {
        .comment-area {
            bottom: 72px;
            
        }
    }
}

@media only screen and (max-width: 1500px) {
    .comment-box{
        .comment-list{
            padding: 14px 22px;
        }
        .comment-area{
            width: 100%;
        }
    } 
}

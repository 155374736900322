.nav-area {
  flex: 0 0 auto;
  .hide-area {
    position: relative;
    overflow-x: hidden;
    width: 64px;
    transition: all 0.6s ease;
    height: 100vh;

    &.opened {
      width: 258px;
    }
  }

  height: 100%;
  overflow: hidden;
  .main-link {
    width: 64px;
    transition: all 0.6s ease;
    overflow-x: hidden;
    .navbar {
      &.bg-dark {
        background-color: #0c192d !important;
        position: fixed;
        top: 0px;
        bottom: 0px;
        padding: 28px 0px 64px;
        z-index: 3;
        width: 64px;
        overflow-x: hidden;
        transition: all 0.6s ease;       
        
        .navbar-nav {
          margin-right: auto;
        }
        .navbar-brand {
          color: #fff;
          font-family: "poppins";
          font-size: 20px;
          padding: 0px;
          width:100%;
          margin:0px 0px 15px 0px;
          .icon-width{
            padding:0px 20px 0px 15px;
            img {
              width: 32px;
              height: 32px;
            }
          }          
        }
        .blank-space{
          background-color: transparent;
          width: 100%;
          height: 100%;
        }
        
        .navbar-nav{
          width:100%;
        }
        .nav-link {
          display: block;
          position: relative;
          //padding: 0.5rem 0rem;
          padding: 0;
          //color: #7e94b5;
          color: #fff;
          font-size: 16px;
          font-family: "OpenSans";
          margin:0px 8px 8px;
          height:40px;
          background-color: transparent;
          border-radius: 3px;
          .svg-inline--fa,
          img {
            width: 25px;
          }
          img {
            width: 24px;
            height: 24px;
          }
          .default-profile{
            svg{
              width: 24px;
              height: 24px;
              color:#fff;
              // fill: #fff;
              // border-radius: 50%;
              // background-color: #0c192d;
            }
          }
          &:hover,
          &.active
           {
            color: #1acaa3;
            background-color: #16263e;            
            img {
              filter: invert(58%) sepia(100%) saturate(323%) hue-rotate(117deg)
                brightness(89%) contrast(103%);
              &.profileimg {
                filter: none;
              }
            }            
          }
          .profileimg {
            border-radius: 50%;
          }
          &.margin-bottom {
            margin-bottom: 24px;
          }
          
          .icon-width {
            width: 48px;
            height: 40px;
            padding-left: 12px;
            padding-right: 12px;
            line-height: 0px;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .lable{
            margin-left: 8px;
          }
          &.profile{
            cursor:pointer;
            margin:0px;
            background-color: #0C1626;
            padding: 14px 20px 14px 14px;
            right: 0;
            left: 0;
            height: 64px;
            position: absolute;
            bottom: -64px;
            .icon-width{
              width:36px;
              height: 36px;
              padding:0px;
              margin-right: 14px;
              .actual-profile, .default-profile{
                position: relative;
                .pips{
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  right:0px;
                  top: 22px;
                  border-radius: 50%;
                  background: #D3412E;
                  border: 2px solid #FFFFFF;
                  &.green{
                    background: #1ACAA3;                    
                  }
                }
              } 
              img{
                width:36px;
                height: 36px;
              }
              .default-profile{
                svg{
                  width:36px;
                  height: 36px;
                }
              }
              
            }
            .lable{
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              margin-left: 0px;
              color: #FFFFFF;
               .userName{
                max-width:151px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
               }            
              .position{
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: #84888C;
                max-width:151px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .arrow{
              svg{
                fill: #fff;
              }
            }
            &:hover{
              background-color: #07101E;              
            }
          }
        }
        .show {
          .nav-link {
            color: #1acaa3;
          }
        }
        .dropdown-menu {
          position: fixed;
          top: 0px;
          left: 48px;
          border: 0px;
          bottom: 0px;
          background-color: #16263e;
          margin: 0px;
          border-radius: 0px;
        }
        // .dropdown-toggle {
        //   &::after {
        //     content: none;
        //   }
        // }
      }
    }
    &.opened {
      //width: 280px;
      width: 258px;
      .bg-dark {
        //width: 280px;
        width: 258px;
        //box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.5);        
      }
    }
    .badge{
      width: 16px;
      height: 16px;
      background: #364760;
      border-radius: 100px;
      color: #FFFFFF;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 10px;
      margin: 0px 5px;
      &.pos-abs{
        position: absolute;
        top: 5px;
        left: 26px;
        margin:0px;
      }
    }
  }
  .toggle-btn {
    transition: all 0.6s ease;
    position: absolute;
    top: 20px;
    // z-index: 1006;
    z-index: 12;
    left: 60px;
    &.opened {
      // left:250px;
      left: 228px;
      //z-index: 1005;
    }
    &.icon-change {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      // z-index: 1005;
      z-index: 12;
    }
    button {
      outline: 0px;
      border: 0px;
      background: transparent;
    }
    &.pin-hover{
      position: fixed;
      z-index: 1006;
      top: 21px;
      left: 65px;
      transition: all 0.3s ease-in-out;     
      &.slide{
        left: 235px;    
      }
      .btn-primary{
        background: transparent;
        padding: 0;
        border: 0;
        &:hover, &:active, &:not(:disabled):not(.disabled):active{
          background: transparent;
        }
      }      
    }
    &.special-condition{
      transition: all 0s ease-in-out;
    }
  }
  .profile-submenu{
    position: absolute;
    width:290px;
    //height:478px;
    left: 74px;
    right: 0px;
    bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #E0E3E6;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    z-index: 1000;
    transition: all 0.6s ease;
    &.opened{
      left: 268px;
    }
    .profile-sec{
      .profile-img{
        margin-top:24px;
        margin-bottom: 16px;
        .default-profile{
          svg{
            width:80px;
            height:80px;
          }
        }
        img{
          width:80px;
          height:80px;
          border-radius: 50%;
        }
      }
      .profile-details{  
        font-family: "OpenSans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #1D1E20;
        .name{
          text-align: center;
        }     
        .department{
          text-align: center;
          margin-top:4px;
          font-weight: normal;
          color: #84888C;
        }
        .punch-sec{
          text-align: center;
          margin-top:16px;
          .punch-in{
            border: 1px solid #E0E3E6;
            background-color: #fff;            
            border-radius: 3px;
            padding:10px 14px;
            font-family: "OpenSans";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #1D1E20;
            svg{
              fill:#D3412E;
              margin-right:6px;
            }
            &.out{
              svg{
                fill:#1ACAA3;                
              }
            }
          }
        }
      }
    }
    .navigation-section{
      padding-top:8px;
      border-top:1px solid #E0E3E6;
      margin-top:28px;
      margin-bottom:8px;
      .section{
        .nav{
          .nav-link{
            font-family: "OpenSans";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #1D1E20;
            padding:11px 17px;           
          }
          .separator{
            margin-bottom: 8px;
            margin-top:8px;
            width:100%;
            height:1px;
            background-color:#E0E3E6;            
          }
        }
        .default-theme-toggle{
          padding: 11px 17px;
        }
      }
    }
  }
  &.devenv{
    .main-link{
      .navbar{
        &.bg-dark{
          background-color: #393939 !important;
          .nav-link{
            &.active, &:hover{
              background-color: #4f4f4f;
            }
            &.profile{
              background-color: #232323;
              &:hover{
                background-color: #232323;
              }
            }
          }
        }
      }
    }
    .sub-menu{
      background-color: #4f4f4f;      
    }
    
  }
  &.stagingenv{
    .main-link{
      .navbar{
        &.bg-dark{
          background-color: #015424 !important;
          .nav-link{
            &.active, &:hover{
              background-color: #017632;
            }
            &.profile{
              background-color: #003315;
              &:hover{
                background-color: #003315;
              }
            }
          }
        }
      }
    }
    .sub-menu{
      background-color: #017632;      
    }
    .badge{
      background: #003315;
    }
  }
  &.demoeenv{
    .main-link{
      .navbar{
        &.bg-dark{
          background-color: #6a0011 !important;
          .nav-link{
            &.active, &:hover{
              background-color: #950018;
            }
            &.profile{
              background-color: #40000a;
              &:hover{
                background-color: #40000a;
              }
            }
          }
        }
      }
    }
    .sub-menu{
      background-color: #950018;      
    }
    .badge{
      background: #40000a;
    }
  }
  
}

.sub-menu {
  background-color: #16263e;
  top: 0px;
  left: 64px;
  bottom: 64px;
  z-index: 3;
  position: absolute;
  width: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  .add-padding {
    padding: 10px;
    padding-right: 0px;
  }
  .SideBurgerIcon {
    color: #7e94b5;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 19px;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      color: #1acaa3;
    }
  }
  &.sec-list {
    .sec-name {
      color: #fff;
      font-size: 20px;
      margin-bottom: 42px;
      margin-top: 25px;
      padding: 0px 1rem;
      line-height: 100%;
    }
    .scroll-area {
      height: calc(100vh - 157px);
      overflow: auto;
      overflow-x: hidden;
    }
    .section {
     // margin-bottom: 24px; 
      .section-name {
        color: #7e94b5;
        font-size: 12px;
        padding: 0px 1rem;
        margin-bottom: 12px;
        font-family: "Poppins-Medium";
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        line-height: 18px;
        //max-width:168px;
        //max-width: 158px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 24px;
      }
      .nav-link {
        color: #fff;
        font-size: 14px;
        font-family: "OpenSans";
        padding: 4px 1rem;
        height: 26px;
        margin-bottom: 2px;
        &:hover,
        &.active{
          color: #1acaa3;
          background-color: transparent;
          .nav-beta-tag {
            font-size: 10px !important;
            line-height: 18px !important;
            padding: 2px !important;
            background-color: #2caf92 !important;
            color: #16263e;
            border-radius: 3px;
            font-family: "OpenSans-Semibold";
            margin-left: 3px;
          }
        }
        .ellipsis {
          //max-width:168px;
          max-width: 158px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .nav-beta-tag {
            font-size: 10px !important;
            line-height: 18px !important;
            padding: 2px !important;
            background-color: #fff;
            color: #16263e;
            border-radius: 3px;
            font-family: "OpenSans-Semibold";
            margin-left: 3px;
          }
        }
      }
      &:last-child{
        padding-bottom: 24px;
      }
    }
  }
  &.opened {
    //width: 216px;
    width: 194px;
  }
  &.height{
    bottom:0px;
  }
}
.hover-menu {
  .sub-menu {
    position: fixed;
    left: 64px;   
    width: 0px;
    z-index: 1006;
    overflow:hidden;
    white-space:nowrap;
    display: inline-block;  
     
    //transition: all 0.6s ease;
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  &.opened {
    .sub-menu{
        width: 193px;
        //display: block;
        //visibility: visible;
        //opacity: 1;
    }
  }
}

.small-width {
  .nav-area {
    .toggle-btn {
      left: 70px;
      &.opened {
        left: 228px;
      }
    }
  }
}

.nav-area-mobile{
  .nav-open{
    .badge{
      width: 16px;
      height: 16px;
      background: #364760;
      border-radius: 100px;
      color: #FFFFFF;
      font-family: "OpenSans";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 10px;
      margin: 0px 5px;
    }
  }
} 

.screen-action{
    .screen-heading{
        font-size:32px;
        line-height: 32px;
        color: #364760;
        font-family: 'poppinsbold';
        padding-bottom: 10px;
        padding-right: 5px;
    }
}

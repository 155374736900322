$light: #ebf0f4;
$dark: #0e223f;
$primary: #1d3be2;
// $secondary: #6c757d;
$info: #3e3ed3;
$success: #00752b;
// $warning: #e1c626;
// $danger: #ec1319;

$nav-link-color: var(--bs-body-color);
$nav-underline-gap: 2.5rem;
$nav-underline-link-active-color: var(--bs-body-color);
@import "bootstrap/scss/bootstrap.scss";

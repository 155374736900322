button:focus {
  outline: 0;
  box-shadow: none !important;
}
.btn-toolbar > * + * {
  margin-left: 15px;
}
*{
  &:focus-visible{
    outline: 0;
  }
}
a {
  transition: all 0.5s ease;
}
.btn {
  font-size: 14px;
  font-family: "OpenSans-Bold";
  line-height: 18px;
  border-radius: 3px;
  cursor: pointer;
  height: 40px;
  padding: 0px 16px;
  border: none;
  //background-color: #f4f6f8;
  letter-spacing: 0px;
  transition: all 0.5s ease;
  color: #3f4347;
  &.w-100 {
    width: 100%;
  }
  //transition: all 0.5s;
  &.wht-bg {
    background-color: transparent;
    height: 32px;
    padding: 0px 20px;
    &.rm-pad {
      padding: 0px;
    }
    &.txt-green {
      color: #164da0;
    }
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
    }
  }
  &.btn-sm {
    font-size: 10px;
    line-height: 10px;
    padding: 7px 12px;
    letter-spacing: 0.5px;
  }
  &.btn-default {
    background-color: #f4f6f8;
    box-shadow: 0 2px 4px 0 rgba(33, 43, 54, 0.16);
    &:focus {
      background-color: #f4f6f8;
      box-shadow: 0 2px 4px 0 rgba(33, 43, 54, 0.16);
    }
    &:hover,
    &:active {
      background-color: #dfe3e8;
      box-shadow: 0 2px 4px 0 rgba(33, 43, 54, 0.16);
    }
  }
  &.btn-primary {
    background-color: #164da0;
    color: #fff;
    border: 1px solid #164da0;
    &.height-35{
      height: 35px;
    }
    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      // background-color: #ffffff;
      // color: #164da0;
      // border-color: #164da0;
      background-color: #24426e;
      color: #fff;
      border-color: #24426e;
    }
    &:focus {
      background-color: #24426e;
      color: #fff;
      border-color: #24426e;
 
    }
    &:disabled {
      &:hover {
        background-color: #164da0;
        color: #fff;
        border: 1px solid #164da0;
      }
    }
    &.btn-outline-primary {
      color: #000000;
      background-color: transparent;
      border:1;
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background-color: #ffffff;
        color: #000000;
      }
      &.more {
        padding: 0px 9px;
        background: #ffffff; // new added
        svg {
          fill: #164da0;
        }
        &::after {
          content: none;
        }
        &:hover {
          background: #364760;
          border-color: #364760;
            svg {
              fill: #fff;
            }
        }
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          //background: #364760;
          //border-color: #364760;
          // svg {
          //   fill: #fff;
          // }
        }
      }
      &:disabled {
        &:hover {
          color: #164da0;
          background-color: transparent;
        }
      }
    }
    &.btn-discard {
      background-color: transparent;
      color: #15b79e;
      border: 0px;
      margin-right: 16px;
    }
    &.btn-link {
      background-color: transparent;
      color: #15b79e;
      border-color: transparent;
      cursor: pointer;
      padding: 0px;
      &:hover,
      &:active,
      &:not(:disabled):not(.disabled):active {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.btn-details, &.btn-back, &.btn-ghost {
      background-color: transparent;
      color: #164da0;
      border: 0px;
    }
    &.btn-ghost-light {
      background-color: transparent;
      color: #84888C;
      border: 0px;
    }
    &.fa-trash {
      border-radius: 3px;
      background: #f4f6f8;
      padding: 8px;
      height: 36px;
      cursor: pointer;
      border: 0px;
      background: transparent;
      &:after {
        content: none;
      }
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: #e8eaed;
        cursor: pointer;
      }
    }
    &.hover-blue{
      &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active{
        background-color: transparent!important;
        color: #fff;
        border-color: #364760;
        cursor: pointer!important;
      }
    }
    &.error{
      color: #B12A18;
      background-color: transparent;
      border: 1px solid #B12A18;
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background-color: #B12A18;
        color: #fff;
      }
      &.warning{
        color: #863902;
        background-color: transparent;
        border: 1px solid #863902;
        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          background-color: #863902;
          color: #fff;
        }
      }
    }
    &.wht-bg-outline{
        color: #3F4347;
        background-color: #fff;
        border: 1px solid #E0E3E6;
        font-family: "OpenSans";
        font-style: normal;
        display: inline-flex !important;
        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          color: #3F4347;
          background-color: #fff;
        }
        &.remove-bdr{
          background-color:transparent;
          border-color: transparent;
          &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          color: #3F4347;
          background-color:transparent;
          border-color: transparent;
        }
        }
    }
  }
  &.btn-primary-white {
    background-color: #164da0;
    color: #fff;
    border: 1px solid #ffffff;
    &.height-35{
      height: 35px;
    }
    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      // background-color: #ffffff;
      // color: #164da0;
      // border-color: #164da0;
      background-color: #24426e;
      color: #fff;
      border-color: #24426e;
    }
    &:focus {
      background-color: #24426e;
      color: #fff;
      border-color: #24426e;
 
    }
    &:disabled {
      &:hover {
        background-color: #164da0;
        color: #fff;
        border: 1px solid #164da0;
      }
    }
    &.btn-outline-primary {
      color: #000000;
      background-color: transparent;
      border:1;
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background-color: #ffffff;
        color: #000000;
      }
      &.more {
        padding: 0px 9px;
        background: #ffffff; // new added
        svg {
          fill: #164da0;
        }
        &::after {
          content: none;
        }
        &:hover {
          background: #364760;
          border-color: #364760;
            svg {
              fill: #fff;
            }
        }
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          //background: #364760;
          //border-color: #364760;
          // svg {
          //   fill: #fff;
          // }
        }
      }
      &:disabled {
        &:hover {
          color: #164da0;
          background-color: transparent;
        }
      }
    }
    &.btn-discard {
      background-color: transparent;
      color: #15b79e;
      border: 0px;
      margin-right: 16px;
    }
    &.btn-link {
      background-color: transparent;
      color: #15b79e;
      border-color: transparent;
      cursor: pointer;
      padding: 0px;
      &:hover,
      &:active,
      &:not(:disabled):not(.disabled):active {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.btn-details, &.btn-back, &.btn-ghost {
      background-color: transparent;
      color: #164da0;
      border: 0px;
    }
    &.btn-ghost-light {
      background-color: transparent;
      color: #84888C;
      border: 0px;
    }
    &.fa-trash {
      border-radius: 3px;
      background: #f4f6f8;
      padding: 8px;
      height: 36px;
      cursor: pointer;
      border: 0px;
      background: transparent;
      &:after {
        content: none;
      }
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: #e8eaed;
        cursor: pointer;
      }
    }
    &.hover-blue{
      &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active{
        background-color: transparent!important;
        color: #fff;
        border-color: #364760;
        cursor: pointer!important;
      }
    }
    &.error{
      color: #B12A18;
      background-color: transparent;
      border: 1px solid #B12A18;
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background-color: #B12A18;
        color: #fff;
      }
      &.warning{
        color: #863902;
        background-color: transparent;
        border: 1px solid #863902;
        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          background-color: #863902;
          color: #fff;
        }
      }
    }
    &.wht-bg-outline{
        color: #3F4347;
        background-color: #fff;
        border: 1px solid #E0E3E6;
        font-family: "OpenSans";
        font-style: normal;
        display: inline-flex !important;
        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          color: #3F4347;
          background-color: #fff;
        }
        &.remove-bdr{
          background-color:transparent;
          border-color: transparent;
          &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          color: #3F4347;
          background-color:transparent;
          border-color: transparent;
        }
        }
    }
  }
  &.btn-emphasis {
    background-color: #fca147;
    color: #fff;
    &:focus {
      background-color: #fca147;
    }
    &:hover,
    &:active {
      background-color: #c47215;
    }
  }
  &.btn-danger {
    background-color: #d3412e;
    color: #ffffff;
    color: #fff;
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #B12A18;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &.green-text-btn-blue{
    color:#164da0;
    border-color:#fff;
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #364760;
      color:#fff;
      border-color:#364760;
    }
  }
}
.btn1 {
  font-size: 16px;
    font-family: "OpenSans-SemiBold" !important;
    line-height: 18px;
    border-radius: 3px;
    cursor: pointer;
    height: 40px;
    padding: 0px 16px;
    border: none;
    letter-spacing: 0px;
    transition: all 0.5s ease;
    color: #3f4347;

  &.w-100 {
    width: 100%;
  }
  //transition: all 0.5s;
  &.wht-bg {
    background-color: transparent;
    height: 32px;
    padding: 16px 0px 20px 80px;
    &.rm-pad {
      padding: 0px;
    }
    &.txt-green {
      color: #164da0;
    }
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
    }
  }
  &.btn-sm {
    font-size: 10px;
    line-height: 10px;
    padding: 7px 12px;
    letter-spacing: 0.5px;
  }
  &.btn-default {
    background-color: #f4f6f8;
    box-shadow: 0 2px 4px 0 rgba(33, 43, 54, 0.16);
    &:focus {
      background-color: #f4f6f8;
      box-shadow: 0 2px 4px 0 rgba(33, 43, 54, 0.16);
    }
    &:hover,
    &:active {
      background-color: #dfe3e8;
      box-shadow: 0 2px 4px 0 rgba(33, 43, 54, 0.16);
    }
  }
  &.btn-primary {
    background-color: #164da0;
    color: #fff;
    border: 1px solid #164da0;
    &.height-35{
      height: 35px;
    }
    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      // background-color: #ffffff;
      // color: #164da0;
      // border-color: #164da0;
      background-color: #24426e;
      color: #fff;
      border-color: #24426e;
    }
    &:focus {
      background-color: #24426e;
      color: #fff;
      border-color: #24426e;
 
    }
    &:disabled {
      &:hover {
        background-color: #164da0;
        color: #fff;
        border: 1px solid #164da0;
      }
    }
    &.btn-outline-primary {
      color: #000000;
      background-color: transparent;
      border:0;
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background-color: #ffffff;
        color: #000000;
      }
      &.more {
        padding: 0px 9px;
        background: #ffffff; // new added
        svg {
          fill: #164da0;
        }
        &::after {
          content: none;
        }
        &:hover {
          background: #364760;
          border-color: #364760;
            svg {
              fill: #fff;
            }
        }
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          //background: #364760;
          //border-color: #364760;
          // svg {
          //   fill: #fff;
          // }
        }
      }
      &:disabled {
        &:hover {
          color: #164da0;
          background-color: transparent;
        }
      }
    }
    &.btn-discard {
      background-color: transparent;
      color: #164da0;
      border: 0px;
      margin-right: 16px;
    }
    &.btn-link {
      background-color: transparent;
      color: #164da0;
      border-color: transparent;
      padding: 0px;
      &:hover,
      &:active,
      &:not(:disabled):not(.disabled):active {
        text-decoration: underline;
      }
    }
    &.btn-details, &.btn-back, &.btn-ghost {
      background-color: transparent;
      color: #164da0;
      border: 0px;
    }
    &.btn-ghost-light {
      background-color: transparent;
      color: #84888C;
      border: 0px;
    }
    &.fa-trash {
      border-radius: 3px;
      background: #f4f6f8;
      padding: 8px;
      height: 36px;
      cursor: pointer;
      border: 0px;
      background: transparent;
      &:after {
        content: none;
      }
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: #e8eaed;
      }
    }
    &.hover-blue{
      &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active{
        background-color: #364760;
        color: #fff;
        border-color: #364760;
      }
    }
    &.error{
      color: #B12A18;
      background-color: transparent;
      border: 1px solid #B12A18;
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background-color: #B12A18;
        color: #fff;
      }
      &.warning{
        color: #863902;
        background-color: transparent;
        border: 1px solid #863902;
        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          background-color: #863902;
          color: #fff;
        }
      }
    }
    &.wht-bg-outline{
        color: #3F4347;
        background-color: #fff;
        border: 1px solid #E0E3E6;
        font-family: "OpenSans";
        font-style: normal;
        display: inline-flex !important;
        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          color: #3F4347;
          background-color: #fff;
        }
        &.remove-bdr{
          background-color:transparent;
          border-color: transparent;
          &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
          color: #3F4347;
          background-color:transparent;
          border-color: transparent;
        }
        }
    }
  }

  &.btn-emphasis {
    background-color: #fca147;
    color: #fff;
    &:focus {
      background-color: #fca147;
    }
    &:hover,
    &:active {
      background-color: #c47215;
    }
  }
  &.btn-danger {
    background-color: #d3412e;
    color: #ffffff;
    color: #fff;
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #B12A18;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &.green-text-btn-blue{
    color:#164da0;
    border-color:#fff;
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #364760;
      color:#fff;
      border-color:#364760;
    }
  }
}
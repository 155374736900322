@mixin customCheckBoxFullHalfCheckedProp {
  .form-check-input {
    margin-top: 0;
    margin-right: 8px;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #c7c8ca;
    padding: 4px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    top: 2px;
    outline: none;
    cursor: pointer;

    &:disabled {
      background-color: #84888c !important;
      border: 1px solid #84888c !important;
    }

    &:checked {
      background-color: #2caf92;
      border: 1px solid #2caf92;
    }

    &:checked:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 5px;
      display: block;
      width: 3px;
      height: 5px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:indeterminate {
      background-color: #2caf92;
      border: 1px solid #2caf92;
    }

    &:indeterminate:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 3px;
      display: block;
      width: 5px;
      height: 5px;
      border: solid #fff;
      border-width: 2px 0 0 0;
      transform: rotate(0deg);
    }
  }

  .form-check-label {
    margin-top: 14px;
    cursor: pointer;
  }
}

.vendorSearch {
  .SearchInput {
    .searchVendor {
      padding-right: 31px !important;
      //text-transform: uppercase;
    }

    .drop-arrow {
      margin-top: -23px;
      float: right;
      margin-right: 10px;
    }
  }

  .postingToolTip {
    margin: 10px 0 0 4px;
    cursor: pointer !important;
    ;
    pointer-events: visible !important;
    opacity: 1 !important;
    ;
  }

  //position: relative;
  .VendorList {
    position: absolute;

    .scrollList {
      max-height: calc(100vh - 300px);
      border: 1px solid #ced4da;
      width: 400px;
      overflow: auto;
      position: relative;
      background: #fff;
      z-index: 999;
      border-radius: 2px;

      .borderDiv {
        border-bottom: 2px solid #ced4da;

        .CurrentEHID {
          scroll-behavior: smooth;
          overflow: hidden;

          p {
            padding: 12px 0px 0px 28px;
            // margin-left: 4px
          }

          .disabledRow {
            pointer-events: none;
            opacity: 0.5;
          }

          //padding: 16px;
          //margin-left: 10px;
          p {
            font-family: "OpenSans-Semibold";
          }

          .currentHIDRepeat {
            //margin-bottom: 12px;
            //padding-top: 35px; // Scroller css
            height: auto;

            .selectableDiv {
              cursor: pointer;
              width: auto;
              word-wrap: break-word;
              margin-left: 18px;
            }

            .showBadge {
              //width: 72% !important;
            }

            .VendorListRow {
              padding: 10px;
              //margin-bottom: -10px;
              //margin-left: 4px;
            }
          }

          .ListContract {
            padding: 10px;
            margin-bottom: 14px;
            //margin-left: 26px;
            height: 70px;
          }

          .ListContract:hover,
          .ListContract:focus {
            outline: none;
            text-decoration: none;
            background-color: #e9ecef;
            border-radius: 4px;
            height: 70px;
          }

          .vendorListItem:hover,
          .vendorListItem:focus {
            outline: none;
            text-decoration: none;
            background-color: #e9ecef;
            border-radius: 4px;
          }

          .vendorName {
            font-size: 15px;
            //font-weight: bold;
            //color: #1d1e20;
            //text-transform: uppercase;
            font-family: "OpenSans-Bold"; //"OpenSans-Semibold";
            font-style: normal;
            display: block;
            width: 100%;
            word-wrap: break-word;
          }

          .contracts {
            font-size: 12px;
            color: #1d1e20;
            font-style: normal;
            display: block;
            line-height: 22px;
            min-height: 8px;
            font-family: "OpenSans-Semibold";
          }

          .contractsDetails {
            font-size: 12px;
            color: #474a4e;
            font-family: "OpenSans-Semibold";
            font-style: normal;
            display: block;
            line-height: 22px;
          }

          .rightIcon {
            float: right;
            // margin-top: -28px;
            cursor: pointer;
            margin-top: 13px;
          }

          .AddVendorrightIcon {
            float: right;
            //margin-top: -28px;
            color: #1acaa3;
            margin-top: 13px;
            cursor: pointer;
          }

          .addVendorRow1 {
            display: inline-flex;

            .customeCheck {
              top: 31px;
            }

            .addVendorRow1 {
              text-transform: uppercase;
            }
          }

          .addVendorRow2 {
            .singleSelect {
              .single-select-image {
                .filter-btn {
                  width: 100%;
                  border: 1px solid #ced4da;
                  background-color: #fff;
                  border-radius: 0.25rem;
                  transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                  padding: 0px 8px;
                  height: 36px;

                  .txt-area {
                    max-width: 80%;
                  }

                  &:focus {
                    border-color: #364760 !important;

                    .drop-arrow {
                      svg {
                        stroke: #1d1e20;
                      }
                    }
                  }
                }

                .dropdown-menu {
                  .list-area {
                    min-height: auto;
                    max-height: 250px;
                  }
                }
              }

              .single-select-image {
                .dropdown-toggle {
                  width: 100%;
                  height: 36px;
                  padding: 0px 8px;
                  font-family: "OpenSans";
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 18px;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 1px solid #ced4da;
                  border-radius: 0.25rem;
                  transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;

                  .txt-area {
                    color: #1d1e20;

                    &.placeholder {
                      color: #84888c;
                    }

                    .drop-arrow {
                      svg {
                        stroke: #1d1e20;
                      }
                    }
                  }

                  &:hover {
                    background-color: #fff !important;
                    border: 1px solid #2caf92 !important;

                    .txt-area {
                      color: #1d1e20;

                      &.placeholder {
                        color: #84888c;
                      }
                    }

                    .drop-arrow {
                      svg {
                        stroke: #1d1e20;
                      }
                    }
                  }

                  &:focus {
                    border: 1px solid #2caf92 !important;
                  }
                }

                .dropdown-menu {
                  .list-area {
                    min-height: auto;
                  }
                }

                &.show {
                  .dropdown-toggle {
                    background-color: #fff !important;
                    border: 1px solid #2caf92 !important;
                    border-radius: 0.25rem;
                    transition: border-color 0.15s ease-in-out,
                      box-shadow 0.15s ease-in-out;

                    .drop-arrow {
                      svg {
                        stroke: #1d1e20;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ContractDetailHeader {
      border-bottom: 1px solid #ced4da;
      padding: 14px;
      background: #fff;
      border: 1px solid #ced4da;
      padding: 14px;
      z-index: 999999999999999;
      position: relative;

      .vendorDetailTitle {
        font-family: "OpenSans-Bold";
        color: #1d1e20;
        //margin: 0px 0px 0px 10px;
      }

      .VendorDetailBackButton {
        color: #0e9074;
        margin: 0px 5px 2px 0px;
      }

      .VendorDetailBackButton.VendorDetailBackButton:focus {
        outline: none;
        text-decoration: none;
        background-color: #e9ecef;
        border-radius: 4px;
      }
    }

    .AddVendorFooter {
      background-color: #efefef;
      border: 1px solid #ced4da;
      min-height: 55px;
      height: auto;
      word-wrap: break-word;
      position: absolute;
      z-index: 999;
      margin-top: -2px;
      width: 400px;

      .addVendorButton {
        height: 100%;
        width: 100%;
        text-align: left;
        color: #1d1e20;
        margin-top: 18px;
        margin-bottom: 18px;

        .BottomVendorName {
          display: block;
          /* or inline-block */
          text-overflow: ellipsis;
          word-wrap: break-word;
          overflow: hidden;
          max-height: 3.6em;
          line-height: 1.8em;
          //margin-top: 15px
        }
      }
    }

    .AddVendorFooter:hover,
    .AddVendorFooter:focus {
      outline: none;
      text-decoration: none;
      background-color: #e9ecef;
      border-radius: 4px;
    }

    .AddContractFooter {
      background-color: #fff;
      border: 1px solid #ced4da;
      height: 55px;
      position: absolute;
      z-index: 999;
      width: 400px;
      margin-top: -2px;

      .addVendorButton {
        height: 100%;
        width: 100%;
        text-align: left;
        color: #1d1e20;
      }
    }

    .addVendorButton:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #e9ecef;
    }

    .GenericVendor {
      margin-top: -1px;
      border: 1px solid #ced4da;
      padding: 16px;
      font-size: 15px;
      font-family: "OpenSans-Bold";
      font-style: normal;
      display: block;
      width: 100%;
      word-wrap: break-word;
      z-index: 9999999;
      position: relative;
      background: rgb(255, 255, 255);
      z-index: 999;
      cursor: pointer;
      padding-left: 27px;
    }

    .GenericVendor:hover,
    .GenericVendor:focus {
      outline: none;
      text-decoration: none;
      background-color: #e9ecef;
      border-radius: 4px;
    }
  }

  .VendorListLoading {
    background: #fff;
    width: 300px;
    //padding: 10px;
    //position: absolute;
    border-radius: 4px;
    z-index: 999;
    padding: 16px;
    margin-left: 12px
  }

  .addVendorForm {
    border: 1px solid #ced4da;
    width: 562px;
    height: auto; //calc(72vh - 120px);
    overflow: auto;
    position: absolute;
    background: #fff;
    z-index: 999;
    border-radius: 2px;
    overflow: inherit;

    .borderDiv {
      .alert-danger {
        color: #b12a18 !important;
        background-color: #ffefed !important;
        border-color: #b12a18 !important;
      }

      border-bottom: 2px solid #ced4da;

      .NewContractHeader {
        padding: 2px;
        border-bottom: 1px solid #ced4da;
        font-family: "OpenSans-Bold";

        .NewContractBackButton {
          color: #0e9074;
          margin: 0px 5px 2px 0px;
          cursor: pointer;
        }
      }

      .NewContractHeader2 {
        padding: 15px 15px 27px 15px;
        border-bottom: 1px solid #ced4da;
        font-family: "OpenSans-Bold";

        .NewContractBackButton {
          color: #0e9074;
          margin: 0px 5px 2px 0px;
          cursor: pointer;
        }
      }

      .CurrentEHID {
        padding: 16px;
        margin-left: 10px;

        .addVendorRow1 {
          display: inline-flex;

          .NewVendorName {
            width: 365px;
          }

          .customeCheck {
            top: 31px;
          }
        }

        .addVendorRow2 {
          .singleSelect {
            .single-select-image {
              .filter-btn {
                width: 100%;
                border: 1px solid #ced4da;
                background-color: #fff;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                padding: 0px 8px;
                height: 36px;

                .txt-area {
                  max-width: 80%;
                }

                &:focus {
                  border-color: #364760 !important;

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }
              }

              .dropdown-menu {
                width: auto;

                .list-area {
                  min-height: auto;
                  max-height: 250px;
                }
              }
            }

            .single-select-image {
              .dropdown-toggle {
                width: 100%;
                height: 36px;
                padding: 0px 8px;
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;

                .txt-area {
                  color: #1d1e20;

                  &.placeholder {
                    color: #84888c;
                  }

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }

                &:hover {
                  background-color: #fff !important;
                  border: 1px solid #2caf92 !important;

                  .txt-area {
                    color: #1d1e20;

                    &.placeholder {
                      color: #84888c;
                    }
                  }

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }

                &:focus {
                  border: 1px solid #2caf92 !important;
                }
              }

              .dropdown-menu {
                .list-area {
                  min-height: auto;
                }
              }

              &.show {
                .dropdown-toggle {
                  background-color: #fff !important;
                  border: 1px solid #2caf92 !important;
                  border-radius: 0.25rem;
                  transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }
              }
            }
          }

          .customeCheck {
            top: 31px;
          }

          .salesCheck {
            margin-left: -15px;
          }

          .VendorSalesHelpIcon {
            top: 31px;
            position: relative;
            margin-left: 8px;
            margin-bottom: 60px;
          }

          .customRow2 {
            //margin-bottom: 12px;
          }
        }

        .addVendorRowNew {
          border-bottom: 2px solid #ced4da;
          border-radius: 2px;
          margin-bottom: 12px;

          .singleSelect {
            width: 20%;

            .single-select-image {
              .filter-btn {
                width: 100%;
                border: 1px solid #ced4da;
                background-color: #fff;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                padding: 0px 8px;
                height: 36px;

                .txt-area {
                  max-width: 80%;
                }

                &:focus {
                  border-color: #364760 !important;

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }
              }

              .dropdown-menu {
                width: auto;

                .list-area {
                  min-height: auto;
                  max-height: 250px;
                }
              }
            }

            .single-select-image {
              .dropdown-toggle {
                width: 100%;
                height: 36px;
                padding: 0px 8px;
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;

                .txt-area {
                  color: #1d1e20;

                  &.placeholder {
                    color: #84888c;
                  }

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }

                &:hover {
                  background-color: #fff !important;
                  border: 1px solid #2caf92 !important;

                  .txt-area {
                    color: #1d1e20;

                    &.placeholder {
                      color: #84888c;
                    }
                  }

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }

                &:focus {
                  border: 1px solid #2caf92 !important;
                }
              }

              .dropdown-menu {
                .list-area {
                  min-height: auto;
                }
              }

              &.show {
                .dropdown-toggle {
                  background-color: #fff !important;
                  border: 1px solid #2caf92 !important;
                  border-radius: 0.25rem;
                  transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }
              }
            }
          }

          .taxIDInput {
            width: 53%;
          }

          input {
            height: 36px;
          }
        }

        .addVendorRow5 {
          .singleSelect {
            width: 56%;

            .single-select-image {
              .filter-btn {
                width: 100%;
                border: 1px solid #ced4da;
                background-color: #fff;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                padding: 0px 8px;
                height: 36px;

                .txt-area {
                  max-width: 80%;
                }

                &:focus {
                  border-color: #364760 !important;

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }
              }

              .dropdown-menu {
                .list-area {
                  min-height: auto;
                  max-height: 250px;
                }
              }
            }

            .single-select-image {
              .dropdown-toggle {
                width: 100%;
                height: 36px;
                padding: 0px 8px;
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;

                .txt-area {
                  color: #1d1e20;

                  &.placeholder {
                    color: #84888c;
                  }

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }

                &:hover {
                  background-color: #fff !important;
                  border: 1px solid #2caf92 !important;

                  .txt-area {
                    color: #1d1e20;

                    &.placeholder {
                      color: #84888c;
                    }
                  }

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }

                &:focus {
                  border: 1px solid #2caf92 !important;
                }
              }

              .dropdown-menu {
                width: auto;

                .list-area {
                  min-height: auto;
                }
              }

              &.show {
                .dropdown-toggle {
                  background-color: #fff !important;
                  border: 1px solid #2caf92 !important;
                  border-radius: 0.25rem;
                  transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }
              }
            }
          }
        }

        .addVendorRow4 {
          .single-select {
            width: 100%;

            .dropdown-toggle {
              background: #fff;
              background-color: #fff;
              height: 35px;

              &::after {
                content: "";
                border: solid #1d1e20;
                border-width: 0 1.6px 1.6px 0;
                display: inline-block;
                padding: 0.15em;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                position: absolute;
                right: 10px;
                top: 5px;
              }

              &:focus {
                background-color: transparent;
              }

              &:disabled {
                background-color: #f3f3f4;
              }
            }

            .search-sec {
              .form-control {
                width: 100% !important;
                border-color: #ced4da;

                &:focus {
                  border-color: #2caf92 !important;
                }
              }
            }
          }
        }

        .addVendorRow7 {
          padding-bottom: 18px;

          .single-select-image {
            .filter-btn {
              width: 100%;
              border: 1px solid #ced4da;
              background-color: #fff;
              border-radius: 0.25rem;
              transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
              padding: 0px 8px;
              height: 36px;

              .txt-area {
                max-width: 80%;
              }

              &:focus {
                border-color: #364760 !important;

                .drop-arrow {
                  svg {
                    stroke: #1d1e20;
                  }
                }
              }
            }

            .dropdown-menu {
              width: 100%;

              //transform: translate3d(0px, 132px, 0px)!important;
              .list-area {
                min-height: auto;
                max-height: 250px;
              }

              .addNewAddressButton {
                border-top: 1px solid #ced4da !important;
              }
            }
          }

          .single-select-image {
            .dropdown-toggle {
              width: 100%;
              height: 36px;
              padding: 0px 8px;
              font-family: "OpenSans";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;

              .txt-area {
                color: #1d1e20;

                &.placeholder {
                  color: #84888c;
                }

                .drop-arrow {
                  svg {
                    stroke: #1d1e20;
                  }
                }
              }

              &:hover {
                background-color: #fff !important;
                border: 1px solid #2caf92 !important;

                .txt-area {
                  color: #1d1e20;

                  &.placeholder {
                    color: #84888c;
                  }
                }

                .drop-arrow {
                  svg {
                    stroke: #1d1e20;
                  }
                }
              }

              &:focus {
                border: 1px solid #2caf92 !important;
              }
            }

            .dropdown-menu {
              .list-area {
                min-height: auto;
              }
            }

            &.show {
              .dropdown-toggle {
                background-color: #fff !important;
                border: 1px solid #2caf92 !important;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;

                .drop-arrow {
                  svg {
                    stroke: #1d1e20;
                  }
                }
              }
            }
          }
        }
      }
    }

    // .footerNewVendor {
    //   padding: 15px 15px 0px 0px;

    //   .VendorCancelBtn {
    //     float: right;
    //     margin-top: 5px;
    //     margin-right: -50px;
    //   }

    //   .VendorCancelBtn:focus {
    //     outline: 0;
    //     box-shadow: 0 0 0 .2rem #3c996e !important;
    //   }

    //   .additionalDetailsCheck {
    //     padding-top: 9px;
    //     float: right;
    //     margin-right: -50px;
    //   }

    //   .addNewVendor {
    //     float: right;
    //     margin-bottom: 12px;
    //   }

    //   .addNewVendor:focus {
    //     outline: 0;
    //     box-shadow: 0 0 0 .2rem #3c996e !important;
    //   }
    // }
    .footerNewVendor {
      min-height: 50px;
      padding: 10px !important;
    }
    .form-check {
      @include customCheckBoxFullHalfCheckedProp;

      .form-check-label {
        top: -2px;
        margin-top: 0px !important;
        cursor: pointer;
        position: relative;
        display: inline-block;
        font-size: 10px;
      }
    }
  }
}

.ContractScrollList {
  max-height: calc(100vh - 300px) !important;
}

.text-secondary {
  color: #2caf92 !important;
}

.VendorDisabled {
  &:read-only {
    background-color: #e9ecef !important;
    opacity: 1 !important;
    border-color: #ced4da !important;
  }
}

.VendorItemCheck {
  margin-left: -21px;
}

.addVendorForm {
  .btn {
    &:disabled {
      opacity: 0.8 !important;

      &:hover {
        border: 1px solid #ced4da !important;
      }
    }
  }
}

.activeVendor:focus-visible {
  outline: none;
  text-decoration: none;
  background-color: #f4f6f8 !important;
  border-radius: 4px;
}

// Main page Add Vendor Modal Start
.VendorDialog-MPM {
  max-width: 48% !important;

  .modal-content {
    .modal-header {
      padding: 20px 32px;

      .modal-title {
        font-family: "Poppins-Medium";
      }

      .close {
        color: #84888c;
        padding: 1.3rem 1rem;
      }
    }

    .modal-body {
      padding: 24px 32px;

      .AddVendor-MPM {
        .form-label {
          font-family: 'OpenSans-Semibold';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
        }

        .form-control {
          font-family: "OpenSans";
          font-size: 14px;
          line-height: 18px;
          color: #1d1e20;
          padding: 10px 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .VendorList-MPM {
          position: absolute;
          margin-top: -17px;

          .scrollList {
            max-height: calc(100vh - 300px);
            border: 1px solid #ced4da;
            width: 400px;
            //overflow: auto;
            position: relative;
            background: #fff;
            z-index: 999;
            border-radius: 2px;

            .borderDiv {
              //border-bottom: 2px solid #ced4da;

              .CurrentEHID {
                scroll-behavior: smooth;
                //overflow: hidden;
                overflow: auto !important;
                max-height: 330px;

                p {
                  padding: 12px 0px 0px 28px;
                  // margin-left: 4px
                }

                .disabledRow {
                  pointer-events: none;
                  opacity: 0.5;
                }

                //padding: 16px;
                //margin-left: 10px;
                p {
                  font-family: "OpenSans-Semibold";
                }

                .currentHIDRepeat {
                  //margin-bottom: 12px;
                  //padding-top: 35px; // Scroller css
                  height: auto;

                  .selectableDiv {
                    cursor: pointer;
                    width: auto;
                    word-wrap: break-word;
                    margin-left: 18px;
                  }

                  .showBadge {
                    //width: 72% !important;
                  }

                  .VendorListRow {
                    padding: 10px;
                    //margin-bottom: -10px;
                    //margin-left: 4px;
                  }
                }

                .ListContract {
                  padding: 10px;
                  margin-bottom: 14px;
                  //margin-left: 26px;
                  height: 70px;
                }

                .ListContract:hover,
                .ListContract:focus {
                  outline: none;
                  text-decoration: none;
                  background-color: #e9ecef;
                  border-radius: 4px;
                  height: 70px;
                }

                .vendorListItem:hover,
                .vendorListItem:focus {
                  outline: none;
                  text-decoration: none;
                  background-color: #e9ecef;
                  border-radius: 4px;
                }

                .vendorName {
                  font-size: 15px;
                  //font-weight: bold;
                  //color: #1d1e20;
                  //text-transform: uppercase;
                  font-family: "OpenSans-Bold"; //"OpenSans-Semibold";
                  font-style: normal;
                  display: block;
                  width: 100%;
                  word-wrap: break-word;
                }

                .contracts {
                  font-size: 12px;
                  color: #1d1e20;
                  font-style: normal;
                  display: block;
                  line-height: 22px;
                  min-height: 8px;
                  font-family: "OpenSans-Semibold";
                }

                .contractsDetails {
                  font-size: 12px;
                  color: #474a4e;
                  font-family: "OpenSans-Semibold";
                  font-style: normal;
                  display: block;
                  line-height: 22px;
                }

                .rightIcon {
                  float: right;
                  // margin-top: -28px;
                  cursor: pointer;
                  margin-top: 13px;
                }

                .AddVendorrightIcon {
                  float: right;
                  //margin-top: -28px;
                  color: #1acaa3;
                  margin-top: 13px;
                  cursor: pointer;
                }
              }
            }
          }

          .AddVendorFooter {
            background-color: #e5f5ff;
            border: 1px solid #ced4da;
            min-height: 55px;
            height: auto;
            word-wrap: break-word;
            position: absolute;
            // z-index: 999;
            z-index: 9;
            //margin-top: -2px;
            margin-top: 0;
            width: 400px;

            .addVendorButton {
              height: 100%;
              width: 100%;
              text-align: left;
              color: #1d1e20;
              margin-top: 18px;
              margin-bottom: 18px;

              .BottomVendorName {
                display: block;
                /* or inline-block */
                text-overflow: ellipsis;
                word-wrap: break-word;
                overflow: hidden;
                max-height: 3.6em;
                line-height: 1.8em;
                //margin-top: 15px
              }
            }
          }

          .AddVendorFooter:hover,
          .AddVendorFooter:focus {
            outline: none;
            text-decoration: none;
            background-color: #e9ecef;
            border-radius: 4px;
          }

          .AddContractFooter {
            background-color: #fff;
            border: 1px solid #ced4da;
            height: 55px;
            position: absolute;
            z-index: 999;
            width: 400px;
            margin-top: -2px;

            .addVendorButton {
              height: 100%;
              width: 100%;
              text-align: left;
              color: #1d1e20;
            }
          }
        }

        .Row2-MPM {
          .taxID-MPM {
            width: 60% !important;
          }

          .singleSelect {
            .single-select-image {
              .filter-btn {
                width: 100%;
                border: 1px solid #ced4da;
                background-color: #fff;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                padding: 0px 8px;
                height: 36px;

                .txt-area {
                  max-width: 80%;
                }

                &:focus {
                  border-color: #364760 !important;

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }
              }

              .dropdown-menu {
                width: 100%;

                .list-area {
                  min-height: auto;
                  max-height: 250px;
                }
              }
            }

            .single-select-image {
              .taxIDDrop-MPM {
                width: 100px;
              }

              .dropdown-toggle {
                //width: 100px;
                height: 36px;
                padding: 0px 8px;
                font-family: "OpenSans";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;

                .txt-area {
                  color: #1d1e20;

                  &.placeholder {
                    color: #84888c;
                  }

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }

                &:disabled {
                  background-color: #f3f3f4;
                }

                &:disabled {
                  border: 0px solid #f3f3f4 !important;

                  &:hover {
                    background-color: #f3f3f4 !important;
                    border: 0px solid #f3f3f4 !important;
                  }
                }

                &:hover {
                  background-color: #fff !important;
                  border: 1px solid #2caf92 !important;

                  .txt-area {
                    color: #1d1e20;

                    &.placeholder {
                      color: #84888c;
                    }
                  }

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }

                &:focus {
                  border: 1px solid #2caf92 !important;
                }
              }

              .dropdown-menu {
                .list-area {
                  min-height: auto;
                }
              }

              &.show {
                .dropdown-toggle {
                  background-color: #fff !important;
                  border: 1px solid #2caf92 !important;
                  border-radius: 0.25rem;
                  transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;

                  .drop-arrow {
                    svg {
                      stroke: #1d1e20;
                    }
                  }
                }
              }
            }
          }
        }

        .Row4-MPM {
          border-bottom: 1px solid #ced4da;
          margin-bottom: 20px;

          .section1-MPM {
            margin-bottom: 20px;
          }
        }

        .Row6-MPM {
          border-bottom: 1px solid #ced4da;
          margin-bottom: 20px;

          .single-select {
            width: 100%;

            .dropdown-toggle {
              background: #fff;
              background-color: #fff;
              height: 35px;

              &::after {
                content: "";
                border: solid #1d1e20;
                border-width: 0 1.6px 1.6px 0;
                display: inline-block;
                padding: 0.15em;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                position: absolute;
                right: 10px;
                top: 5px;
              }

              &:focus {
                background-color: transparent;
              }

              &:disabled {
                background-color: #f3f3f4;
              }
            }

            .search-sec {
              .form-control {
                width: 100% !important;
                border-color: #ced4da;

                &:focus {
                  border-color: #2caf92 !important;
                }
              }
            }
          }
        }

        .salesIcon-MPM {
          margin-left: 6px;
        }

        .addBtn-MPM {
          float: right;
        }
      }
    }

    .modal-footer {
      background: #f4f6f8;
      padding: 20px 32px;
    }
  }


  .custom-btn {
    font-family: "OpenSans";
    color: #1D1E20;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    font-weight: 400;
    width: 100%;
    border: 1px solid #ced4da;
    background-color: #fff;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0px 8px;
    height: 35px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

  .custom-btn:disabled {
    background-color: #f3f3f4;
  }

  .custom-btn {
    .OptionalEmailPH {
      //font-weight: 600;
      font-family: "OpenSans";
      font-size: 14px;
      opacity: 0.5;
      //color:#1d1e20;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .VendorDialog-MPM {
    max-width: 58% !important;
  }
}
@media only screen and (max-width: 1000px) {
  .VendorDialog-MPM {
    max-width: 70% !important;
  }
}

.search-vendor-list-item {
  padding: 5px 0px 5px 10px;

  .list-item {
    font-size: 15px;
    font-family: "OpenSans-Bold";
    font-style: normal;
    display: block;
    //width: 100%;
    width: 330px;
    word-wrap: break-word;

    .contracts {
      font-size: 12px;
      color: #1d1e20;
    }

    .vendorName,
    .contracts {
      line-height: 22px;
    }
  }

  .arrow {
    padding: 10px;
  }
}

.VendorDialog-MPM {
  .AddVendor-MPM {
    .VendorList-MPM {
      .pos-rel {
        position: relative;
        z-index: 2;

        .scrollList {
          max-height: 300px;
          position: unset;

          .CurrentEHID {
            overflow: unset !important;
          }
        }
      }
    }
  }
}

.dropright {
  &.vendor-list-sec {
    position: static;

    .arrow-icon {
      line-height: 0px;
      height: auto;
      width: auto;

      &::after {
        border: 0px;
      }

      padding: 0px;
      background-color: transparent;
      border: 0;

      & svg {
        color: #000;
      }

      &:hover,
      &:focus {
        background-color: transparent;
        border: 0;
      }
    }

    .dropdown-menu {
      max-height: 300px;
      overflow: auto;
      z-index: 1001;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: block;
          padding: 10px;
          border-bottom: 1px solid #ccc;

          &:last-child {
            border-bottom: 0px;
          }
        }
      }
    }
  }

  &.show {
    .btn-primary {
      &.dropdown-toggle {
        background-color: transparent;
        border: 0;
      }
    }
  }
}

.VendorModalInput::placeholder {
  font-weight: 100;
  font-family: "OpenSans";
  font-size: 14px;
}


// Main page Add Vendor Modal END


.footerNewVendor {
  min-height: 50px;
  padding: 10px !important;
}




.close
{
border: none;


}